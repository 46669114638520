import React from 'react';
import SgLayout from '~layouts/sg-layout';

const SgTypographyBody = () => {
    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Typography - Body styles</h1>
                    <h3>Examples</h3>
                </div>
            </section>
            <section className="project-example eg-typopgrahy-styles">
                <div className="container eg-body-styles">
                    <p><strong>Default</strong> - Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad delectus ipsam corrupti. Ullam quas voluptatem, rerum sed, itaque deserunt culpa, tempore mollitia ipsam dolor facere unde vero reiciendis consectetur?</p>
                    <p className="body-lg"><strong>Body LG - Lorem</strong>, ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad delectus ipsam corrupti. Ullam quas voluptatem, rerum sed, itaque deserunt culpa, tempore mollitia ipsam dolor facere unde vero reiciendis consectetur?</p>
                    <p className="body-sm"><strong>Body - SM - Lorem</strong>, ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad delectus ipsam corrupti. Ullam quas voluptatem, rerum sed, itaque deserunt culpa, tempore mollitia ipsam dolor facere unde vero reiciendis consectetur?</p>
                    <p className="body-xs"><strong>Body - XS</strong> - Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit ad delectus ipsam corrupti. Ullam quas voluptatem, rerum sed, itaque deserunt culpa, tempore mollitia ipsam dolor facere unde vero reiciendis consectetur?</p>
                    <a href="#" className="link-style">Example link style</a>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>How to use</h2>
                    <p>Add <code>@include body-SIZE;</code> to any SCSS file.</p>
                    <h3>Size options</h3>
                    <ol>
                        <li>regular (defualt</li>
                        <li>lg</li>
                        <li>sm</li>
                        <li>xs</li>
                    </ol>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgTypographyBody;
